<template>
	<div>
		<p>
			<span class="title">订单编号：</span><span>{{ order.orderId }}</span>
		</p>
		<p>
			<span class="title">预约人姓名：</span><span>{{ order.buyerName }}</span>
		</p>
		<p>
			<span class="title">预约人联系电话：</span><span>{{ order.buyerPhone }}</span>
		</p>
		<p>
			<span class="title">预约人详细地址：</span><span>{{ order.homeAdder }}</span>
		</p>
		<p>
			<span class="title">老人能力评估：</span>
			<span>{{ orderInfo.elderlyAbility ? getContent(ELDERLYABILITY, orderInfo.elderlyAbility, 'label', 'text') : '--' }}</span>
		</p>
		<p>
			<span class="title">老人类型：</span>
			<span>{{ orderInfo.elderlyType ? getContent(ELDERLYTYPE, orderInfo.elderlyType, 'label', 'text') : '--' }}</span>
		</p>
		<p>
			<span class="title">服务机构：</span><span>{{ orderInfo.serviceName ? orderInfo.serviceName : '--' }}</span>
		</p>
		<p>
			<span class="title">服务人员姓名：</span><span>{{ order.staffName }}</span>
		</p>
		<p>
			<span class="title">服务人员电话：</span><span>{{ order.staffPhone }}</span>
		</p>
		<p>
			<span class="title">服务人员详细地址：</span><span>{{ order.staffAdder }}</span>
		</p>
		<p>
			<span class="title">订单状态：</span><span>{{ order.statusStr }}</span>
		</p>
		<p>
			<span class="title">预定套餐：</span><span>{{ order.comboName }}</span>
		</p>
		<p>
			<span class="title">关联居家养老床位：</span><span>{{ order.buyerName }}</span>
		</p>
		<p>
			<span class="title">服务时长：</span><span>{{ orderInfo.times ? orderInfo.times : '--' }}</span>
		</p>
		<p>
			<span class="title">附件内容：</span>
			<template v-if="order.serviceFileAddress.length > 0">
				<el-tooltip
					v-for="(item, index) in order.serviceFileAddress"
					:key="index"
					class="item"
					effect="dark"
					content="点击下载"
					placement="top-end"
				>
					<el-link :underline="false" type="primary" @click="downFile(item.value)">查看附件</el-link>
				</el-tooltip>
			</template>
			<span v-else>--</span>
		</p>
		<p>
			<span class="title" v-if="order.reason">取消原因：</span><span v-if="order.reason">{{ order.reason }}</span>
		</p>
	</div>
</template>

<script>
import { webInfo } from '@/api/orderManage'
import { ELDERLYABILITY, ELDERLYTYPE } from '@/data/dataDictionary'
import { getContent, downloadFile } from '@/utils/index'
export default {
	name: 'DetailInfo',
	props: {
		orderId: {
			type: String,
			default: '',
		},
		orderInfo: {
			type: Object,
			default: () => {
				return {}
			},
		},
	},
	data() {
		return {
			ELDERLYABILITY,
			ELDERLYTYPE,
			order: {
				buyerName: '',
				buyerPhone: '',
				comboName: '',
				homeAdder: '',
				orderId: '',
				staffAdder: '',
				staffName: '',
				staffPhone: '',
				statusStr: '',
				userId: '',
				serviceFileAddress: [],
			},
		}
	},
	mounted() {
		this.getInfo()
	},
	methods: {
		getContent,
		async getInfo() {
			const res = await webInfo({
				orderId: this.orderId,
			})
			this.order = res.data
		},
		downFile(url) {
			const href = url
			downloadFile(href, href)
		},
	},
}
</script>

<style lang="scss" scoped>
p {
	color: #999999;
	padding: 0 60px;
}
.title {
	display: inline-block;
	width: 15%;
	color: #333333;
	min-width: 160px;
	// text-align: right;
}
</style>
